import { useRouter } from 'next/router';

import { useDisclosure } from 'utils/hooks';

export default function useLoginModal() {
  const router = useRouter();

  const open = () => {
    router.query.login = 'true';
    router.query.from = router.asPath;
    router.push(router, undefined, { shallow: true });
  };

  const close = () => {
    delete router.query.login;
    delete router.query.from;
    router.push(router, undefined, { shallow: true });
  };

  const isOpen = router.query.login === 'true';

  return {
    isOpen,
    open,
    close,
  };
}
