import { useRouter } from 'next/router';
import { Box } from '@mui/material';

import Modal from 'common/Modal';
import ConfettiOverlay from 'components/ConfettiOverlay';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import { useDisclosure } from 'utils/hooks';
import useSearchParamsModal from 'utils/hooks/useSearchParamsModal';

import useOnboardingModal from '../hooks/useOnboardingModal';

export default function OnboardingModal() {
  const onboardingModal = useOnboardingModal();

  const router = useRouter();

  const isInitial = router.query?.initial;

  const header = isInitial ? (
    <>
      Your account is now ready! ✅ <br />
      You can start using the platform
    </>
  ) : (
    <>
      We&apos;re here to help you get started! 👋 <br />
    </>
  );

  const onClose = () => {
    delete router.query.initial;

    router.query.onboarding = 'true';

    router.asPath = (router.query.from as string) || '/';

    onboardingModal.close();
  };

  return null;

  return (
    <Modal {...onboardingModal} handleClose={onClose}>
      <Modal.Content sx={{ overflow: 'hidden', position: 'relative' }}>
        {isInitial && <ConfettiOverlay />}
        <Box py={4} textAlign="center">
          <Text weight="medium" size="2xl">
            {header}
          </Text>
          <Text size="md" mt={2}>
            Do you have any <strong>questions</strong> or need{' '}
            <strong>onboarding</strong>?
          </Text>
          <Text size="xs" mt={0.5}>
            Pick a time slot that works for you and we&apos;ll be happy to help
          </Text>
          <VStack mt={4} gap={2} maxWidth={'240px'} mx="auto">
            <Button variant="lightOutlined" size="small">
              View calendar
            </Button>
            <Button onClick={onClose} size="small">
              Continue to app
            </Button>
          </VStack>
        </Box>
      </Modal.Content>
    </Modal>
  );
}
