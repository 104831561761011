import Confetti from 'react-confetti';
import { IConfettiOptions } from 'react-confetti/dist/types/Confetti';

interface Props extends Partial<IConfettiOptions> {}

export default function ConfettiOverlay({
  width = 700,
  height = 1000,
  opacity = 0.5,
  numberOfPieces = 20,
  ...props
}: Props) {
  return (
    <Confetti
      {...props}
      {...{
        width,
        height,
        opacity,
        numberOfPieces,
      }}
    />
  );
}
