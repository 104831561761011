import { PiPlus } from 'react-icons/pi';
import { Box, Stack } from '@mui/material';

import Modal from 'common/Modal';
import UnstyledLink from 'common/UnstyledLink';
import Button from 'components/primitives/Button';
import Text from 'components/primitives/Text';
import useMissingSubscriptionModal from 'components/views/missing-subscription/hooks/useMissingSubscriptionModal';
import { useIsMobile } from 'utils/hooks';
import { useCheckSubscription } from 'utils/subscription/useCheckSubscription';
import { SubscriptionType } from 'types/TeamManagement/subscription';

import { useIsLoggedIn } from '../../../../store/AppProvider';

export default function MissingSubscriptionModal() {
  const {
    isOpen,
    minRequiredSubscriptionFromPath,
    close: modalClose,
  } = useMissingSubscriptionModal();
  const isMobile = useIsMobile();
  const isLoggedIn = useIsLoggedIn();

  const { hasSubscriptionExpired, isSubscriptionPlanInsufficient } =
    useCheckSubscription({
      minRequiredSubscriptionType:
        minRequiredSubscriptionFromPath as SubscriptionType,
    });

  const modalContent = getSubscriptionModalContent({
    hasSubscriptionExpired,
    isSubscriptionPlanInsufficient,
  });

  return (
    <Modal
      maxWidth="600px"
      isOpen={isOpen}
      handleClose={() => {
        isMobile && modalClose();
      }}
      transitionDuration={0}
    >
      <Box
        bgcolor="background.paper"
        width="100%"
        height="300px"
        position="relative"
        p={0.5}
        sx={{ overflow: 'hidden' }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            objectFit: 'cover',
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }}
          height="100%"
          src={'https://assets.cdn.cookie3.co/public-beta-launch-xs.mp4'}
        ></video>
      </Box>
      <Modal.Content
        sx={{
          bgcolor: 'neutral.100',
          borderTop: '1px solid',
          borderColor: 'neutral.300',
        }}
      >
        <Stack>
          <Text mb={1} size="xl" weight="medium">
            {modalContent.title}
          </Text>
          <Text mb={3} size="sm" color="secondary">
            {modalContent.body}
          </Text>

          <div className="flex flex-col gap-2">
            <UnstyledLink href="/settings/team-management">
              <Button size="small" fullWidth variant="outline">
                Change team
              </Button>
            </UnstyledLink>
            <UnstyledLink
              navigateWithinTeam={isLoggedIn}
              href="/settings/billing"
            >
              <Button Icon={PiPlus} size="small" fullWidth>
                {modalContent.buttonText}
              </Button>
            </UnstyledLink>
          </div>
        </Stack>
      </Modal.Content>
    </Modal>
  );
}

const getSubscriptionModalContent = ({
  hasSubscriptionExpired,
  isSubscriptionPlanInsufficient,
}: {
  hasSubscriptionExpired: boolean;
  isSubscriptionPlanInsufficient: boolean;
}) => {
  if (hasSubscriptionExpired)
    return {
      title: 'Subscription Expired',
      body: 'It seems that the subscription for your team has expired. To continue enjoying our premium features, please renew your subscription.',
      buttonText: 'Renew subscription',
    };

  if (isSubscriptionPlanInsufficient) {
    return {
      title: `Insufficient Subscription Plan`,
      body: `Oops! It seems that your current subscription plan does not provide access to this feature. To enjoy this feature, consider upgrading your subscription plan.`,
      buttonText: 'Upgrade subscription plan',
    };
  }

  return {
    title: 'No subscription found',
    body: 'It appears that there is no active subscription for your team. To unlock premium features and benefits, please subscribe.',
    buttonText: 'Subscribe now',
  };
};
