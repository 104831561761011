import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Provider } from '@supabase/supabase-js';
import { mutate } from 'swr';

import { SnackbarVariant } from 'utils/constants';
import { useSnackbar } from 'context/SnackbarContext';
import { cookie3Analytics } from 'lib/cookie3-analytics';
import supabase from 'lib/supabase';
import { LoginStep } from 'types/Login';

import { initializeTeam } from './util/initializeTeam';

export default function useLogin() {
  const [loginState, setLoginState] = useState<LoginStep>(LoginStep.READY);

  const router = useRouter();
  const snackbar = useSnackbar();

  const onSocialLogin = useCallback(
    async (provider: Provider) => {
      setLoginState(LoginStep.LOADING);

      delete router.query.login;

      cookie3Analytics.trackEvent({
        category: 'user',
        action: 'login',
        name: provider,
      });
      const req = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location.origin}/api/login/callback`,

          queryParams: {
            ...(router.query as Record<string, string>),
          },
        },
      });
    },
    [router.query],
  );

  const onEmailLogin = useCallback(
    async (email: string) => {
      try {
        setLoginState(LoginStep.LOADING);

        cookie3Analytics.trackEvent({
          category: 'user',
          action: 'login',
          name: 'otp',
        });

        const emailSignIn = await supabase.auth.signInWithOtp({
          email: email,
          options: {
            emailRedirectTo: `${window.location.origin}/api/login/callback`,
          },
        });
      } catch (error) {
        setLoginState(LoginStep.READY);
        snackbar.trigger({
          message: 'Something went wrong. Please try again.',
          variant: SnackbarVariant.error,
        });
      }

      return;
    },
    [snackbar],
  );

  return {
    loginState,
    setLoginState,
    onSocialLogin,
    onEmailLogin,
  };
}
