import { useCallback, useEffect, useState } from 'react';

export const useIsActiveTopbar = (breakpoint: number) => {
  const [activeNavbar, setActiveNavbar] = useState(false);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    currentScrollPos >= breakpoint
      ? setActiveNavbar(true)
      : setActiveNavbar(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return { activeNavbar };
};
