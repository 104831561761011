import { forwardRef } from 'react';
import {
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  useTheme,
} from '@mui/material';

type Props = TextFieldProps & {
  labelColor?: string;
  sx?: SxProps<Theme>;
  adornment?: React.ReactNode;
  adornmentSx?: SxProps<Theme>;
  showCounter?: boolean;
  maxLength?: number;
  inputLength?: number;
  adjoinVariant?: 'adjoin-left' | 'adjoin-right';
};

const Input = forwardRef(
  (
    { labelColor, adornment, sx, error, adjoinVariant, ...props }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();

    const adornmentSxProps = adornment
      ? {
          '& .MuiOutlinedInput-root': {
            paddingLeft: 0,
            padding: 0,
            px: '12px',
          },
          '& .MuiOutlinedInput-input': {
            py: '0px',
          },
          '& .MuiInputBase-inputAdornedStart': {
            pl: '8px',
          },
          '& .MuiInputAdornment-root': {
            py: '23px',
            px: '12px',
            backgroundColor: '#F5F5F5',
            height: '100%',
            borderRight: '1px solid #E9E7E7',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            fontSize: '10px',
            ...props.adornmentSx,
          },
        }
      : {};

    return (
      <TextField
        {...props}
        InputProps={
          adornment
            ? {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      borderRight: '1px solid',
                      px: 1,
                    }}
                  >
                    {adornment}
                  </InputAdornment>
                ),
                ...props.InputProps,
              }
            : props.showCounter
              ? ({
                  endAdornment: (
                    <InputAdornment position="end">
                      {props.inputLength || 0}/{props.maxLength}
                    </InputAdornment>
                  ),
                  ...props.InputProps,
                } as any)
              : props.InputProps
        }
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            background: theme.palette.background.paper,
            borderRadius: '8px',
            fontSize: '14px',
            lineHeight: '16px',
            ...(adjoinVariant && ADJOIN_BASE_ROOT_SX_PROPS[adjoinVariant]),
            ':focus-within': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.neutral[300],
              ...(adjoinVariant &&
                ADJOIN_NOTCHED_OUTLINE_SX_PROPS[adjoinVariant]),
            },
            '& fieldset>legend': {
              fontSize: '12px',
            },
          },
          ' .MuiOutlinedInput-input': {
            padding: '13px 16px',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: '8px',
            color: !!error
              ? theme.palette.named.red
              : theme.palette.text.tertiary,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: !!error
              ? `2px solid ${theme.palette.named.red} !important`
              : `1px solid ${theme.palette.neutral['400']}`,
          },
          '& textarea': {
            padding: '0 !important',
          },
          '& label': {
            color: labelColor || theme.palette.text.primary,
          },
          ...adornmentSxProps,
          ...sx,
        }}
      />
    );
  },
);

Input.displayName = 'Input';
export default Input;

const ADJOIN_BASE_ROOT_SX_PROPS = {
  ['adjoin-left']: {
    borderRadius: '0 8px 8px 0',
  },
  ['adjoin-right']: {
    borderRadius: '8px 0 0 8px',
  },
};

const ADJOIN_NOTCHED_OUTLINE_SX_PROPS = {
  ['adjoin-left']: {
    borderLeftColor: 'transparent',
  },
  ['adjoin-right']: {
    borderRightColor: 'transparent',
  },
};
