export enum LoginStep {
  READY = 'READY',
  LOADING = 'LOADING',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_NOT_DELIVERABLE = 'EMAIL_NOT_DELIVERABLE',
  OTP_WRONG = 'OTP_WRONG',
  VALIDATING = 'VALIDATING',
  LOGGING_IN = 'LOGGING_IN',
  ERROR = 'ERROR',
}
