import { PropsWithChildren } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

export default function ModalContent({
  withDefaultPadding = true,
  children,
  sx,
}: PropsWithChildren<{
  withDefaultPadding?: boolean;
  sx?: SxProps<Theme>;
}>) {
  return (
    <Box pt="16px" pb="24px" px={withDefaultPadding ? '24px' : '0px'} sx={sx}>
      {children}
    </Box>
  );
}
