import { useMemo } from 'react';
import {
  Project,
  Team,
  TeamBillingDetails,
  TeamRole,
  TeamSettings,
} from '@prisma/client';
import { useIsLoggedIn } from 'src/store/AppProvider';
import useSWR from 'swr';

import fetcher from 'utils/fetcher';
import { ChainId } from 'types/Chain';
import { SubscriptionType } from 'types/TeamManagement/subscription';

import { useTeamStore } from '../../store/team/teamStore';

type TeamWithTypedSubscription = Omit<Team, 'subscriptionType' | 'stripeId'> & {
  subscriptionType: SubscriptionType;
};

export type TeamResItem = TeamWithTypedSubscription & {
  projects: Project[];
  users: { role: TeamRole; user: { email: string } }[];
  userRole: TeamRole;
  isSubscriptionActive: boolean;
  billingDetails?: TeamBillingDetails;
  teamSettings?: TeamSettings;
  teamChains?: { chains?: [ChainId, ChainId]; updatedAt: string };
};

export interface UserTeamsRes {
  teams: TeamResItem[];
}

export const useGetTeams = () => {
  const teamStoreId = useTeamStore((state) => state.team?.id);
  const isLoggedIn = useIsLoggedIn();

  const { data, isLoading, isValidating, mutate } = useSWR<UserTeamsRes>(
    isLoggedIn ? '/api/teamManagement' : null,
    fetcher,
  );

  const sortedTeams = useMemo(
    () =>
      data?.teams.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA.getTime() - dateB.getTime();
      }),
    [data?.teams],
  );

  const teams = Boolean(sortedTeams) ? sortedTeams! : [];

  const selectedTeam = teams.find((team) => team.id === teamStoreId);

  return { selectedTeam, teams, isValidating, isLoading, mutate };
};
