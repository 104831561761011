import { Control, Controller, useController, useWatch } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';

import { Label, StyledCheckbox } from 'components/Form';

export interface CheckboxGroupType {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  fieldName: string;
  options: CheckboxGroupType[];
  control: Control<any> | undefined;
  isRequired?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
}

export default function CheckboxGroup({
  control,
  label,
  fieldName,
  options,
  isRequired,
  color = '#fff',
  sx,
}: Props) {
  const {
    field: { ref, value, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name: fieldName,
    control,
    defaultValue: [],
  });

  const checkboxIds = useWatch({ control, name: fieldName }) || [];

  const handleChange = (value: string) => {
    const newArray = [...checkboxIds];
    const item = value;

    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item);

      if (index === -1) {
        newArray.push(item);
      } else {
        newArray.splice(index, 1);
      }
    } else {
      newArray.push(item);
    }

    onChange(newArray);
  };

  return (
    <FormControl sx={sx}>
      {label && <Label label={label} />}
      <FormGroup
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gridGap: '6px',
          color,
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Controller
                rules={{ required: isRequired && 'This field is required' }}
                name={fieldName}
                control={control}
                render={() => (
                  <StyledCheckbox
                    sx={{ color }}
                    checked={value?.some(
                      (checked: string) => checked === option.value,
                    )}
                    {...inputProps}
                    inputRef={ref}
                    onChange={() => handleChange(option.value)}
                  />
                )}
              />
            }
          />
        ))}
      </FormGroup>
      {!!errors?.[fieldName] && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {errors?.[fieldName]?.message}
        </Typography>
      )}
    </FormControl>
  );
}
