import { FormEvent } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Box } from '@mui/material';

import InputField from 'components/Form/InputField';
import Button from 'components/primitives/Button';
import { LoginFormData } from 'types/Login/form';

interface Props<T extends FieldValues> {
  onSubmit: (e?: FormEvent) => Promise<void>;
  buttonContent: React.ReactNode;
  disabled: boolean;
  isLoading: boolean;
  control: Control<LoginFormData>;
}

export default function EmailLoginForm({
  onSubmit,
  control,
  buttonContent,
  disabled,
  isLoading,
}: Props<LoginFormData>) {
  return (
    <form onSubmit={onSubmit}>
      <Box mt={4}>
        {!isLoading && (
          <>
            <InputField
              disabled={isLoading}
              fieldName="email"
              control={control}
              label="Enter your email address"
              placeholder="Email address"
              helperText="We'll send you a one-time password to sign in with."
            />
          </>
        )}
      </Box>
      <Button
        fullWidth
        type="submit"
        isLoading={isLoading}
        disabled={disabled}
        sx={{ mt: 4 }}
      >
        {buttonContent}
      </Button>
    </form>
  );
}
