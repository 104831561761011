import HStack from 'components/primitives/HStack';
import Spinner from 'components/primitives/Spinner';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';

export default function VerifyingState() {
  return (
    <VStack vcenter hcenter fullHeight fullWidth>
      <Spinner size="32px" />
      <Text mt={2} ml={1} size="xs" color="tertiary" weight="medium">
        Verifying...
      </Text>
    </VStack>
  );
}
