const reportModal = {
  successTitle: 'Your report is being analysed!',
  successDescription:
    'Before you drink your coffee and eat your cookie 🍪 it will be visible on your Account Page.',
  existsTitle: 'You have already generated a report for this address!',
  existsDescription:
    'The report is already visible or will be shortly in the Account Page, as it was already generated successfully.',
  failureTitle: 'Oops something went wrong!',
  failureDescription:
    'We are sorry but the analysis status of the report is invalid or unavailable.',
};

export { reportModal };
