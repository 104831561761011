import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControlLabel, SxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/system';

import { StyledCheckbox } from 'components/Form';

interface Props {
  control: any;
  name: string;
  label: ReactNode;
  fontSize?: { xs: string; sm: string } | string;
  sx?: SxProps<Theme>;
  onChangeCallback?: (e: any) => void;
  isError?: boolean;
}

export default function CheckboxField({
  control,
  name,
  label,
  fontSize = '11px',
  onChangeCallback,
  isError,
  sx,
}: Props) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        render={({
          fieldState: { error },
          field: { onChange, value, ref, ...field },
        }) => (
          <>
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize,
                  color: error
                    ? `${theme.palette.named.red} !important`
                    : undefined,
                  fontWeight: 500,
                },
                '& .MuiFormControlLabel-label': {
                  userSelect: 'none',
                },
              }}
              labelPlacement="end"
              value="end"
              label={label}
              control={
                <StyledCheckbox
                  inputRef={ref}
                  isError={!!error || isError}
                  checked={!!value}
                  onChange={(e) => {
                    if (onChangeCallback) onChangeCallback(e);
                    onChange(e);
                  }}
                  {...field}
                />
              }
            />
          </>
        )}
      />
    </Box>
  );
}
