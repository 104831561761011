import { useEffect } from 'react';

import isProductionEnv from 'utils/isProductionEnv';

export const cookie3Analytics = {
  trackEvent: (args: {
    category: string;
    action: string;
    name?: string;
    value?: number;
  }) => {
    if (!isProductionEnv) {
      console.group('%c --- tracking event (debug) ---', `color: cyan`);
      console.info('category:', args.category);
      console.info('action:', args.action);
      console.info('name:', args.name);
      console.info('value:', args.value);
      console.groupEnd();

      return;
    }

    try {
      window?.cookie3?.trackEvent(args);
    } catch (error) {}
  },
  useTrackPageView: (pageName: string, param?: string) => {
    useEffect(() => {
      if (!isProductionEnv) {
        console.info(
          `%c --- tracking page view (debug): ${pageName} ---`,
          `color: cyan`,
        );

        return;
      }

      try {
        window?.cookie3?.trackEvent({
          category: 'pageview',
          action: pageName,
          name: param,
        });
      } catch (error) {}
    }, [pageName, param]);
  },
};
