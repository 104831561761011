import { useEffect, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

let useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect,
  isConfig = (value: any) =>
    value && !Array.isArray(value) && typeof value === 'object',
  emptyArray = [] as unknown as any[],
  defaultConfig = {} as any,
  _gsap = gsap; // accommodates situations where different versions of GSAP may be loaded, so a user can gsap.registerPlugin(useGSAP);

const useGSAP = (callback?: any, dependencies = emptyArray) => {
  let config = defaultConfig;
  if (isConfig(callback)) {
    config = callback;
    callback = null;
    dependencies = 'dependencies' in config ? config.dependencies : emptyArray;
  } else if (isConfig(dependencies)) {
    config = dependencies as any;
    dependencies = 'dependencies' in config ? config.dependencies : emptyArray;
  }
  callback &&
    typeof callback !== 'function' &&
    console.warn('First parameter must be a function or config object');
  const { scope, revertOnUpdate } = config,
    mounted = useRef(false),
    context = useRef(_gsap.context(() => {}, scope)),
    contextSafe = useRef((fun: any) =>
      // @ts-ignore
      context.current.add(null, fun as any),
    ),
    deferCleanup = dependencies && dependencies.length && !revertOnUpdate;
  useIsomorphicLayoutEffect(() => {
    callback && context.current.add(callback, scope);
    if (!deferCleanup || !mounted.current) {
      // React renders bottom-up, thus there could be hooks with dependencies that run BEFORE the component mounts, thus cleanup wouldn't occur since a hook with an empty dependency Array would only run once the component mounts.
      return () => context.current.revert();
    }
  }, dependencies as any);
  deferCleanup &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIsomorphicLayoutEffect(() => {
      mounted.current = true;
      return () => context.current.revert();
    }, emptyArray as any);
  return { context: context.current, contextSafe: contextSafe.current };
};
useGSAP.register = (core: any) => {
  _gsap = core;
};
useGSAP.headless = true; // doesn't require the window to be registered.

export { useGSAP };
