import { useRouter } from 'next/router';

import { useDisclosure } from '../useDisclosure';

interface Args {
  param: string;
}

export default function useSearchParamsModal(
  args: Args,
): Omit<ReturnType<typeof useDisclosure>, 'toggle'> {
  const router = useRouter();
  const isOpen = router.query[args.param] === 'true';

  const open = () => {
    router.query[args.param] = 'true';
    router.push(router, undefined, { shallow: true });
  };

  const close = () => {
    delete router.query[args.param];
    router.push(router, undefined, { shallow: true });
  };

  return {
    isOpen,
    open,
    close,
  };
}
