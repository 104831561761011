import {
  createClientComponentClient,
  createPagesBrowserClient,
} from '@supabase/auth-helpers-nextjs';
import { createBrowserClient } from '@supabase/ssr';
import { createClient } from '@supabase/supabase-js';
const supabaseUrl = 'https://gsxnmvrobzbrkjelnzev.supabase.co';
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_CLIENT_KEY!;

const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: false, // All our access is from server, so no need to persist the session to browser's local storage
    autoRefreshToken: false, // All my Supabase access is from server, so no need to refresh the token
    flowType: 'pkce',
  },
});

const spbs = createPagesBrowserClient({
  supabaseKey,
  supabaseUrl,
});

export default spbs;
