import React, { FC, StyleHTMLAttributes } from 'react';
import { useTheme } from '@mui/material';

interface SpinnerProps {
  size?: string;
  variant?: 'light' | 'dark';
}

function Spinner({ size = '24px', variant = 'dark' }: SpinnerProps) {
  const theme = useTheme();

  const getSpans = () => {
    return [...new Array(12)].map((_, index) => (
      <span
        key={`spinner-${index}`}
        style={getSpanStyle(index) as StyleHTMLAttributes<HTMLSpanElement>}
      />
    ));
  };

  const getSpanStyle = (index: number) => {
    const rotation = 300 - index * 30;
    const delay = -0.1 * index;

    return {
      backgroundColor:
        variant === 'dark'
          ? theme.palette.neutral[800]
          : theme.palette.neutral[100],
      position: 'absolute',
      top: '-3.9%',
      width: '24%',
      height: '8%',
      left: '-10%',
      borderRadius: '10%',
      animation: `spinner 1.2s linear ${delay}s infinite normal none running`,
      animationDirection: 'reverse',
      transform: `rotate(${rotation}deg) translate(146%)`,
    };
  };

  return (
    <div
      style={{
        display: 'block',
        boxSizing: 'border-box',
        width: size,
        height: size,
        padding: 0,
        margin: 0,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          left: '50%',
          top: '50%',
        }}
      >
        {getSpans()}
      </div>
      <style>
        {`
        @keyframes spinner {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0.15;
          }
        }
        `}
      </style>
    </div>
  );
}

export default Spinner;
