import { useRouter } from 'next/router';

import { useDisclosure } from 'utils/hooks';
import useSearchParamsModal from 'utils/hooks/useSearchParamsModal';

export default function useOnboardingModal(): Omit<
  ReturnType<typeof useDisclosure>,
  'toggle'
> {
  const modal = useSearchParamsModal({ param: 'customer-success-modal' });

  return modal;
}
