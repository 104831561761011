import React, { createContext, Dispatch, SetStateAction } from 'react';

interface IBackdropContext {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultValue = {
  setIsOpen: () => {},
};

const BackdropContext = createContext<IBackdropContext>(defaultValue);

export default BackdropContext;
