import { Control, FieldValues, Path, useController } from 'react-hook-form';
import {
  BaseTextFieldProps,
  Box,
  FormLabel,
  styled,
  SxProps,
  Theme,
} from '@mui/material';

import Input from 'common/Input';

interface Props<T extends FieldValues> extends BaseTextFieldProps {
  fieldName: Path<T>;
  placeholder?: string;
  control: Control<T>;
  sx?: SxProps<Theme>;
  labelColor?: string;
  label?: React.ReactNode;
  bottomDescription?: React.ReactNode;
  adornment?: React.ReactNode;
  maxLength?: number;
  showCounter?: boolean;
  inputLength?: number;
}

export default function InputField<T extends FieldValues>({
  label,
  fieldName,
  control,
  bottomDescription,
  adornment,
  ...props
}: Props<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: fieldName,
  });

  return (
    <Box>
      {label && (
        <Box mb={1}>
          <StyledFormLabel>{label}</StyledFormLabel>
        </Box>
      )}

      <Input
        error={!!error}
        helperText={error?.message || bottomDescription}
        adornment={adornment}
        {...props}
        {...field}
      />
    </Box>
  );
}

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.neutral['800'],
  fontSize: '12px',
}));
