import { useEffect } from 'react';
import Smartlook from 'smartlook-client';

import isProductionEnv from 'utils/isProductionEnv';
import { UserInfo } from 'types/User';

interface Props {
  user?: UserInfo;
}

export const useSmartlookAnalytics = ({ user }: Props) => {
  useEffect(() => {
    if (!isProductionEnv) return;
    // ? we only want to initialize smartlook if the user is logged in
    // ? so that we can identify the user
    if (user && user.data) {
      Smartlook.init(`2b1499a209f6670ae9669734438234ec47c70814`, {
        region: 'eu',
      });
      Smartlook.identify(user.data.id, {
        email: user.data.email,
        isPartner: !!user.data.partner,
        createdAt: user.data.createdAt,
      });
    }
  }, [user, user?.data]);
};
