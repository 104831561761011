import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getThemeBasedOnMode } from 'theme/index';
import { getStorageData, setStorageData } from 'utils/storage';

export type ThemeMode = 'light' | 'dark';

const ColorModeContext = createContext<{
  toggleColorMode: () => void;
  mode: ThemeMode;
}>({
  toggleColorMode: () => {},
  mode: 'light',
});

const THEME_STORAGE_KEY = 'cookie-theme';

export const useColorMode = () => {
  const context = useContext(ColorModeContext);

  if (!context) {
    throw new Error('useColorMode must be used within a ColorModeProvider');
  }

  return context;
};

export const ColorModeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // TODO: uncomment when dark/light theme should be enabled
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const [mode, setMode] = useState<ThemeMode>(
  //   prefersDarkMode ? 'dark' : 'light'
  // );

  // useLayoutEffect(() => {
  //   if (getStorageData(THEME_STORAGE_KEY)) {
  //     setMode(getStorageData(THEME_STORAGE_KEY) as unknown as ThemeMode);
  //   }
  // }, []);

  const [mode, setMode] = useState<ThemeMode>('light');

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode((prevMode) => {
          const valueToSet = prevMode === 'light' ? 'dark' : 'light';
          setStorageData(THEME_STORAGE_KEY, valueToSet);
          return valueToSet;
        });
      },
    }),
    [mode],
  );

  // @ts-ignore
  const theme = useMemo(() => createTheme(getThemeBasedOnMode(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
