import React, { useEffect, useState } from 'react';
import { Box, Slide, styled } from '@mui/material';

import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import CloseIcon from 'theme/icons/x';
import {
  getStorageData,
  LocalStorageData,
  setStorageData,
} from 'utils/storage';

const COOKIE_STORAGE_KEY = 'cookieConsent';

export default function CookieDrawer() {
  const [drawerState, setDrawerState] = useState<LocalStorageData>(null);

  // useEffect to run code only client side
  useEffect(() => {
    setDrawerState(getStorageData(COOKIE_STORAGE_KEY));
  }, []);

  const handleAcceptButton = () => {
    setStorageData(COOKIE_STORAGE_KEY, { wasAccepted: true });
    setDrawerState({ wasAccepted: true });
  };

  const handleExitIconClick = () => {
    setDrawerState({ wasAccepted: true });
  };

  if (drawerState?.wasAccepted) return null;
  return (
    <Slide in direction="left" timeout={500}>
      <Wrapper>
        <HStack alignItems={'flex-start'} gap={2}>
          <Box>
            <Text size="xs" mb={2} textAlign={'justify'} color="secondary">
              In order to improve the operation of our app, we use cookies. By
              using the website without changing cookie settings, you consent to
              their storage or use. The cookie settings can be changed in the
              web browser. More details about cookies can be found in our{' '}
              <a
                href="https://cookie3.co/privacy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
              .
            </Text>

            <HStack gap={2}>
              <Button
                fullWidth
                size="small"
                variant="outline"
                sx={{ minWidth: '82px', mt: { xs: 1, sm: 0 }, height: '40px' }}
                onClick={handleExitIconClick}
              >
                Decline
              </Button>
              <Button
                fullWidth
                size="small"
                sx={{ minWidth: '82px', mt: { xs: 1, sm: 0 }, height: '40px' }}
                onClick={handleAcceptButton}
              >
                Accept
              </Button>
            </HStack>
          </Box>
          <CloseIconContainer>
            <Box
              onClick={handleExitIconClick}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <CloseIcon />
            </Box>
          </CloseIconContainer>
        </HStack>
      </Wrapper>
    </Slide>
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  overflowX: 'hidden',
  borderRadius: '16px',
  border: '1px solid #E5E5E5',
  padding: '16px 12px',
  zIndex: 1500,
  background: '#fff',
  bottom: 0,
  boxShadow: '0px -3px 4px rgba(0, 0, 0, 0.04)',

  [theme.breakpoints.up('sm')]: {
    width: '400px',
    right: 16,
    bottom: 16,
  },
}));

const CloseIconContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: 16,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginBottom: 8,
  },
}));
