const colors = {
  neutral: {
    '900': '#101840',
    '800': '#474D66',
    '700': '#696F8C',
    '600': '#8F95B2',
    '500': '#C1C4D6',
    '400': '#D8DAE5',
    '300': '#E6E8F0',
    '200': '#EDEFF5',
    '100': '#F4F6FA',
    '75': '#F9FAFC',
    '50': '#FAFBFF',
    '25': '#FDFDFF',
  },
  base: {
    black: '#000000',
    darkNeutral: '#0A172A',
    white: '#FFFFFF',
  },
  blue: {
    '600': '#1F3D99',
    '500': '#2952CC',
    '400': '#356BF6',
    '300': '#9DB5FF',
    '200': '#D6E0FF',
    '100': '#EBF0FF',
    '50': '#F3F6FF',
  },
  green: {
    '600': '#059669',
    '500': '#10B981',
    '400': '#34D399',
    '300': '#6EE7B7',
    '200': '#A7F3D0',
    '100': '#D1FAE5',
    '50': '#ECFDF5',
  },
  yellow: {
    '600': '#66460D',
    '500': '#996A13',
    '400': '#FFB020',
    '300': '#FFD079',
    '200': '#FFDFA6',
    '100': '#FFEFD2',
    '50': '#FFFAF1',
  },
  red: {
    '600': '#E11D48',
    '500': '#F43F5E',
    '400': '#FB7185',
    '300': '#FDA4AF',
    '200': '#FECDD3',
    '100': '#FFE4E6',
    '50': '#FFF1F2',
  },
  violet: {
    '600': '#524988',
    '500': '#6E62B6',
    '400': '#897AE3',
    '300': '#B8AFEE',
    '200': '#D0CAF4',
    '100': '#E7E4F9',
    '50': '#F8F7FD',
  },
  teal: {
    '600': '#0F5156',
    '500': '#10899E',
    '400': '#25CBD6',
    '300': '#7CE0E6',
    '200': '#A8EAEF',
    '100': '#D3F5F7',
    '50': '#F2FCFD',
  },
  pink: {
    '600': '#AB36F6',
    '500': '#B65AF0',
    '400': '#D38DFF',
    '300': '#E1BAFA',
    '200': '#EED2FF',
    '100': '#F8EDFF',
    '50': '#FCF8FF',
  },
  orange: {
    '600': '#85462B',
    '500': '#B25E3A',
    '400': '#DE7548',
    '300': '#EBAC91',
    '200': '#F2C8B6',
    '100': '#F8E3DA',
    '50': '#FDF4F4',
  },
};

const textColors = {
  white: '#FFFFFF',
  black: '#000000',
  primary: 'text.primary',
  secondary: 'text.secondary',
  tertiary: 'text.tertiary',
  error: colors.red[600],
  ...colors,
};

export default textColors;
