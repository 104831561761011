type Variant =
  | 'primary'
  | 'blue'
  | 'outline'
  | 'disabled'
  | 'text'
  | 'textDark'
  | 'light'
  | 'lightOutlined'
  | 'error'
  | 'dark'
  | 'white';

interface ISpec {
  bgcolor: string;
  color: string;
  borderColor?: string;
}

const disabledSpec: ISpec = {
  bgcolor: 'neutral.200',
  color: 'neutral.500',
  borderColor: 'neutral.300',
};

const buttonColors: Record<
  Variant,
  {
    bgcolor: string;
    color: string;
    borderColor?: string;
    hover: ISpec;
    active?: ISpec;
    disabled?: ISpec;
  }
> = {
  primary: {
    bgcolor: 'text.primary',
    color: 'white',
    borderColor: 'text.primary',
    hover: {
      bgcolor: '#383838',
      color: 'white',
      borderColor: '#383838',
    },
    active: {
      bgcolor: '#383838',
      color: 'white',
      borderColor: '#383838',
    },
    disabled: disabledSpec,
  },
  blue: {
    bgcolor: 'blue.400',
    color: 'white',
    borderColor: 'blue.400',
    hover: {
      bgcolor: 'blue.500',
      color: 'white',
      borderColor: 'blue.500',
    },
    active: {
      bgcolor: 'blue.500',
      color: 'white',
      borderColor: 'blue.600',
    },
    disabled: disabledSpec,
  },
  outline: {
    bgcolor: 'transparent',
    color: 'base.black',
    borderColor: 'neutral.400',
    hover: {
      bgcolor: 'neutral.50',
      color: 'neutral.800',
      borderColor: 'neutral.300',
    },
    active: {
      bgcolor: 'neutral.200',
      color: 'neutral.800',
      borderColor: 'blue.400',
    },
    disabled: disabledSpec,
  },
  white: {
    bgcolor: 'white',
    color: 'base.black',
    borderColor: 'neutral.400',
    hover: {
      bgcolor: 'neutral.100',
      color: 'base.black',
      borderColor: 'neutral.300',
    },
    active: {
      bgcolor: 'neutral.200',
      color: 'base.black',
      borderColor: 'blue.400',
    },
  },
  disabled: {
    bgcolor: 'neutral.300',
    color: 'neutral.400',
    borderColor: 'neutral.300',
    hover: {
      bgcolor: 'neutral.300',
      color: 'neutral.400',
      borderColor: 'neutral.300',
    },
  },
  text: {
    bgcolor: 'transparent',
    color: '#0066FF',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'transparent',
      color: 'blue.500',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'transparent',
      color: 'blue.600',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  textDark: {
    bgcolor: 'transparent',
    color: 'black',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'transparent',
      color: 'neutral.700',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'transparent',
      color: 'neutral.800',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  light: {
    bgcolor: 'blue.100',
    color: 'blue.400',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'blue.50',
      color: 'blue.500',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'blue.100',
      color: 'blue.600',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  lightOutlined: {
    bgcolor: 'blue.100',
    color: 'blue.400',
    borderColor: 'blue.200',
    hover: {
      bgcolor: 'blue.50',
      color: 'blue.500',
      borderColor: 'blue.200',
    },
    active: {
      bgcolor: 'blue.100',
      color: 'blue.600',
      borderColor: 'blue.200',
    },
    disabled: disabledSpec,
  },
  error: {
    bgcolor: 'red.400',
    color: 'white',
    borderColor: 'red.400',
    hover: {
      bgcolor: 'red.500',
      color: 'white',
      borderColor: 'red.500',
    },
    active: {
      bgcolor: 'red.600',
      color: 'white',
      borderColor: 'red.600',
    },
  },
  dark: {
    bgcolor: 'black',
    color: 'white',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'neutral.800',
      color: 'white',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'neutral.800',
      color: 'white',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
};

export default buttonColors;
