import { Provider } from '@supabase/supabase-js';

export interface ProviderSpec {
  name: Provider;
  icon: string;
}

const providers: ProviderSpec[] = [
  { name: 'google', icon: '/icons/socials/google-color.png' },
  // {
  //   name: 'linkedin',
  //   icon: '/icons/socials/linkedin-color.png',
  // },
];

export default providers;
