import { useRouter } from 'next/router';

import { SubscriptionType } from 'types/TeamManagement/subscription';

export default function useMissingSubscriptionModal(
  minRequiredSubscriptionType?: SubscriptionType,
) {
  const router = useRouter();

  const open = () => {
    router.query.missingSubscription = 'true';
    if (minRequiredSubscriptionType) {
      router.query.missingSubscriptionType = minRequiredSubscriptionType;
    }
    router.query.from = router.asPath;
    router.replace(router, undefined, { shallow: true });
  };

  const close = async () => {
    delete router.query.missingSubscription;
    delete router.query.missingSubscriptionType;
    delete router.query.from;
    router.replace(router, undefined, { shallow: true });
  };

  const isOpen = router.query.missingSubscription === 'true';
  const minRequiredSubscriptionFromPath = router.query.missingSubscriptionType;

  return {
    isOpen,
    open,
    close,
    minRequiredSubscriptionFromPath,
  };
}
