import React, { ReactNode, useState } from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import BackdropContext from 'context/BackdropContext';

interface Props {
  children: ReactNode;
}

export default function WithBackdrop({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BackdropContext.Provider value={{ setIsOpen }}>
      {children}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BackdropContext.Provider>
  );
}
