import { ComponentProps } from 'react';
import Link from 'next/link';

import { useGetTeamSlugs } from 'utils/teamManagement/useGetTeamSlugs';

import { useIsLoggedIn } from '../../store/AppProvider';

interface Props extends ComponentProps<typeof Link> {
  navigateWithinTeam?: boolean;
  disabled?: boolean;
}

export default function UnstyledLink({
  children,
  navigateWithinTeam = false,
  disabled,
  ...props
}: Props) {
  const isLoggedIn = useIsLoggedIn();
  const { teamSlug, projectSlug } = useGetTeamSlugs();

  const href =
    navigateWithinTeam && isLoggedIn
      ? `/${teamSlug}/${projectSlug}${props.href}`
      : props.href;

  return (
    <Link
      {...props}
      href={href}
      passHref
      style={{
        all: 'unset',
        cursor: 'pointer',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
      className={props.className}
      tabIndex={disabled ? -1 : undefined}
    >
      {children}
    </Link>
  );
}
