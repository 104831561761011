import { Radio as MuiRadio, RadioProps } from '@mui/material';
import { styled } from '@mui/material';

interface Props extends Omit<RadioProps, 'color'> {
  isError: boolean;
  color: string;
  iconSize?: number;
}

export default function Radio({
  isError,
  color,
  iconSize = 12,
  ...props
}: Props) {
  return (
    <MuiRadio
      checkedIcon={<CheckedIcon iconSize={iconSize} />}
      icon={<Icon isError={isError} />}
      sx={{
        color,
        '&.Mui-checked': { color },
      }}
      {...props}
    />
  );
}

const Icon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError?: boolean }>(({ theme, isError }) => ({
  borderRadius: '50%',
  margin: 2,
  width: 18,
  height: 18,
  boxShadow: isError
    ? `inset 0 0 0 1px ${theme.palette.named.red}`
    : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  background: '#fff',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.named.grey,
  },
}));

const CheckedIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'iconSize',
})<{ iconSize: number }>(({ theme, iconSize }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    display: 'block',
    width: iconSize,
    height: iconSize,
    content: '""',
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
}));
