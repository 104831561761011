export const getErrorMessage = (error: unknown) => {
  let message: string;

  if (error instanceof Error) {
    message = error?.message || JSON.stringify(error);
  } else if (error && typeof error === 'object' && 'message' in error) {
    message = String(error.message);
  } else if (typeof error === 'string') {
    message = error;
  } else {
    message = 'Something went wrong';
  }

  return message;
};
