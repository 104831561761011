import { createContext, useContext } from 'react';

import { SnackbarVariant } from 'utils/constants';

export type SnackbarDataProps = {
  message: string;
  variant: SnackbarVariant;
  subtitle?: string;
};

interface ISnackbarContext {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarData: React.Dispatch<React.SetStateAction<SnackbarDataProps>>;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
}

const defaultValue = {
  setIsOpen: () => {},
  setSnackbarData: () => {},
  setDuration: () => {},
};

const SnackbarContext = createContext<ISnackbarContext>(defaultValue);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  const { setIsOpen, setSnackbarData, setDuration } = context;

  const trigger = (data: SnackbarDataProps, duration?: number) => {
    setSnackbarData(data);
    setIsOpen(true);
    if (duration) {
      setDuration(duration);
    }
  };

  const hide = () => {
    setIsOpen(false);
  };

  return {
    trigger,
    hide,
  };
};

export default SnackbarContext;
