import { PropsWithChildren } from 'react';
import { PiCheck } from 'react-icons/pi';
import YouTube from 'react-youtube';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';
import { useIsLoggedIn } from 'src/store/AppProvider';

import Modal from 'common/Modal';
import UnstyledLink from 'common/UnstyledLink';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import { useGetTeamSlugs } from 'utils/teamManagement/useGetTeamSlugs';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import RegisterView from '../';
import useRegisterModal from '../hooks/useRegisterModal';

export default function RegisterModal() {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const { teams, isLoading } = useGetTeams();
  const { isOpen } = useRegisterModal();

  const hasMoreThanOneTeam = teams.filter((team) => !team.isDemo).length > 1;
  const isTermsOfServicePage = router.pathname === '/terms';

  const { teamSlug, projectSlug } = useGetTeamSlugs();

  return (
    <Modal
      sx={{
        // set z-index to 1300 to be above the cookie banner
        zIndex: 30000,
      }}
      isOpen={
        isOpen &&
        !hasMoreThanOneTeam &&
        !isLoading &&
        isLoggedIn &&
        !isTermsOfServicePage
      }
      PaperProps={{
        sx: {
          bgcolor: 'transparent',

          '& > div': {
            py: 0,
          },
          maxWidth: '100% !important',
        },
      }}
      handleClose={() => {}}
    >
      <Stack
        gap={2}
        mx="auto"
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Modal.Content
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            pb: 0,
            overflow: 'hidden',
            width: {
              xs: '100%',
              md: '460px',
            },
          }}
          withDefaultPadding={false}
        >
          <VStack fullHeight>
            <Box height="100%">
              <RegisterView />
            </Box>
            <Modal.Actions
              sx={{ bgcolor: 'neutral.100', px: 5, pb: 3, mt: 'auto' }}
            >
              <Box>
                <Text size="sm" weight="medium">
                  Your free plan access - what&apos;s included?
                </Text>
                <Box>
                  <HStack gap={1} mt={1} vcenter>
                    <Box
                      p={0.5}
                      bgcolor="blue.100"
                      display="flex"
                      borderRadius="50%"
                      color="blue.400"
                    >
                      <PiCheck size={14} strokeWidth={10} />
                    </Box>
                    <Text size="xs" color="secondary">
                      Basic website analytics for essential insights.
                    </Text>
                  </HStack>
                  <HStack gap={1} mt={1} vcenter>
                    <Box
                      p={0.5}
                      bgcolor="blue.100"
                      display="flex"
                      borderRadius="50%"
                      color="blue.400"
                    >
                      <PiCheck size={14} strokeWidth={10} />
                    </Box>
                    <Text size="xs" color="secondary">
                      Demo data to help you familiarize yourself with the
                      platform.
                    </Text>
                  </HStack>
                </Box>
              </Box>
            </Modal.Actions>
          </VStack>
        </Modal.Content>
        <Modal.Content
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            width: {
              xs: '100%',
              md: '320px',
            },
            flexShrink: 0,
            overflow: 'hidden',
            py: 0,
          }}
          withDefaultPadding={false}
        >
          <Box>
            <video
              preload="none"
              // lazy load the video
              //

              src="https://assets.cdn.cookie3.co/public-beta-launch-xs.mp4"
              autoPlay
              muted
              loop
              style={{ height: '140px', objectFit: 'cover', width: '100%' }}
            />
          </Box>
          <Box px={3} pb={4} pt={2} height="100%">
            <VStack>
              <Box height="100%">
                <Text weight="medium" size="xl">
                  Start a free trial ✨ today and get:
                </Text>
                <Row>
                  <strong>Team collaboration </strong> for seamless team work
                </Row>
                <Row>
                  Comprehensive Website Analytics with detailed{' '}
                  <strong>on-chain and off-chain insights</strong>
                </Row>
                <Row>
                  <strong>Advanced attribution tracking</strong> to understand
                  user actions leading to on-chain events
                </Row>
                <Row>
                  In-Depth on-chain analytics for crafting{' '}
                  <strong>custom audiences and effective retargeting</strong>
                </Row>
              </Box>
              <Link href={`${teamSlug}/${projectSlug}/settings/billing`}>
                <Button
                  sx={{ mt: 4, width: '100%' }}
                  size="small"
                  variant="lightOutlined"
                >
                  View available plans
                </Button>
              </Link>
            </VStack>
          </Box>
        </Modal.Content>
      </Stack>
    </Modal>
  );
}

function Row(props: PropsWithChildren<{}>) {
  return (
    <HStack gap={1} mt={1} vcenter>
      <Box
        p={0.5}
        bgcolor="blue.100"
        display="flex"
        borderRadius="50%"
        color="blue.400"
      >
        <PiCheck size={14} strokeWidth={10} />
      </Box>
      <Text size="xs" color="secondary">
        {props.children}
      </Text>
    </HStack>
  );
}
