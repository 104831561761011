import { Box, useTheme } from '@mui/material';
import { Provider } from '@supabase/supabase-js';

import Button from 'components/primitives/Button';
import { ProviderSpec } from 'lib/supabase/oauth/config';

interface Props {
  onClick: (provider: Provider) => void;
  loginProviders: ProviderSpec[];
}

export default function SocialLoginButtons({ onClick, loginProviders }: Props) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      columnGap={1.5}
      width="100%"
      overflow="hidden"
    >
      {loginProviders.map((provider) => (
        <Button
          key={provider.name}
          onClick={() => onClick(provider.name)}
          variant="outline"
          sx={{
            height: '50px',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            backgroundImage: `url(${provider.icon})`,
            backgroundSize: '30px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      ))}
    </Box>
  );
}
