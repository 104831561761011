import { forwardRef } from 'react';
import { FiX } from 'react-icons/fi';
import { HiInformationCircle } from 'react-icons/hi';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import { PiWarningFill } from 'react-icons/pi';
import { Box, Snackbar } from '@mui/material';
import { styled } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { TransparentBgButton } from 'common/TransparentBgButton';
import Text from 'components/primitives/Text';
import { SnackbarDataProps } from 'context/SnackbarContext';

interface Props extends SnackbarDataProps {
  isOpen: boolean;
  duration: number;
  handleClose: () => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert ref={ref} variant="filled" {...props} />;
  },
);

export default function CustomSnackbar({
  isOpen = false,
  message,
  variant,
  duration,
  subtitle,
  handleClose,
}: Props) {
  return (
    <StyledSnackbar variant={variant}>
      <Snackbar
        sx={{
          zIndex: 9999999,
        }}
        autoHideDuration={duration}
        onClose={handleClose}
        open={isOpen}
      >
        <Alert
          icon={false}
          severity={variant}
          sx={{
            '& .MuiAlert-message': {
              width: '100%',
              padding: 0,
            },
            '& svg': {
              width: '18px',
              height: '18px',
              fill: SNACKBAR_STYLES[variant].border,
            },
          }}
        >
          <HeadingRow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {SNACKBAR_ICONS[variant]}
              <Text
                sx={{ color: SNACKBAR_STYLES[variant].title, fontWeight: 500 }}
              >
                {message}
              </Text>
            </Box>
            <TransparentBgButton onClick={handleClose}>
              <FiX color={SNACKBAR_STYLES[variant].exitIcon} />
            </TransparentBgButton>
          </HeadingRow>

          {subtitle && (
            <Text color="tertiary" size="sm" mt={0.5} ml="34px">
              {subtitle}
            </Text>
          )}
        </Alert>
      </Snackbar>
    </StyledSnackbar>
  );
}
interface StyledSnackbarProps {
  variant: Props['variant'];
}

const StyledSnackbar = styled(Box)<StyledSnackbarProps>(
  ({ theme, variant }) => ({
    '& .MuiSnackbar-root .MuiPaper-root': {
      position: 'relative',
      width: '100%',
      left: '0',
      background: SNACKBAR_STYLES[variant].background,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '435px',

      padding: '12px 16px',
      border: `1px solid ${SNACKBAR_STYLES[variant].border}`,
      [theme.breakpoints.up('sm')]: {
        width: '400px',
        left: '105px',
      },
    },
  }),
);

const HeadingRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const SNACKBAR_STYLES = {
  success: {
    title: '#317159',
    exitIcon: '#317159',
    background: '#F5FBF8',
    border: '#52BD94',
  },
  error: {
    title: '#A73636',
    exitIcon: '#7D2828',
    background: '#FDF4F4',
    border: '#D14343',
  },
  warning: {
    title: '#996A13',
    exitIcon: '#66460D',
    background: '#FFFAF1',
    border: '#FFB020',
  },
  info: {
    title: '#2952CC',
    exitIcon: '#1F3D99',
    background: '#F3F6FF',
    border: '#3366FF',
  },
};

const SNACKBAR_ICONS = {
  success: <IoCheckmarkCircle />,
  error: <MdError />,
  warning: <PiWarningFill />,
  info: <HiInformationCircle />,
};
