import Script from 'next/script';

export const CookieAnalytics = () => {
  return (
    <Script
      id="cookie-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        var cookie3Options = {"siteId":661,"additionalTracking":true,"cookielessEnabled":true}

        window._paq = window._paq || [];
        (function () {
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = 'https://cdn.cookie3.co/scripts/analytics/latest/cookie3.analytics.min.js'; s.parentNode.insertBefore(g, s);
        })();
         `,
      }}
    />
  );
};
