export enum ErrorType {
  Timeout = 1,
  FilterTooBigCardinality = 3,
}

export class ResponseError extends Error {
  status?: number;
  errorDetails?: {
    errorType: number;
    errorMessage: string;
    errorData: string;
  };
}

const fetcher = async (url: string, ...args: any) => {
  const res = await fetch(url, ...args);
  const data = await res.json();
  if (!res.ok) {
    const error = new ResponseError(data?.message || 'Something went wrong');
    error.status = res.status;

    if (res.status !== 500) {
      error.errorDetails = data;
    }

    throw error;
  }
  return data;
};

export default fetcher;
