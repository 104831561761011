import { useDisclosure } from 'utils/hooks';
import useSearchParamsModal from 'utils/hooks/useSearchParamsModal';

export default function useCalendlyModal(): Omit<
  ReturnType<typeof useDisclosure>,
  'toggle'
> {
  const modal = useSearchParamsModal({ param: 'calendly' });

  return modal;
}
