import { useTeamStore } from '../../store/team/teamStore';

export const useGetTeamSlugs = () => {
  const { team, project } = useTeamStore();

  return {
    shouldFetch: true,
    teamSlug: team?.slug,
    projectSlug: project?.slug,
  };
};
