import { Project } from '@prisma/client';
import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { TeamResItem } from 'services/teamManagement/useGetTeams';

export type TeamStore = {
  team: Pick<TeamResItem, 'id' | 'name' | 'slug'> | null;
  project: Pick<Project, 'id' | 'name' | 'slug'> | null;
  setTeam: (team: TeamResItem | null) => void;
  setProject: (project: Project | null) => void;
  setTeamAndProject: (team: TeamResItem, project: Project) => void;
  clear: () => void;
};

const prepareStore: StateCreator<TeamStore> = (set) => ({
  team: null,
  project: null,
  setTeam: (team) =>
    set(() => {
      if (!team) {
        return {
          team: null,
          project: null,
        };
      }
      return {
        team: {
          id: team.id,
          name: team.name,
          slug: team.slug,
        },
        project: null,
      };
    }),
  setProject: (project) =>
    set(() => ({
      project,
    })),
  setTeamAndProject: (team, project) =>
    set(() => ({
      team: {
        id: team.id,
        name: team.name,
        slug: team.slug,
      },
      project: {
        id: project.id,
        name: project.name,
        slug: project.slug,
      },
    })),
  clear: () =>
    set(() => ({
      team: null,
      project: null,
    })),
});

export const useTeamStore = create(
  persist(prepareStore, {
    name: 'team-store',
    partialize: (state) => ({
      team: state.team,
      project: state.project,
    }),
    version: 1.3,
  }),
);
