import Modal from 'common/Modal';

import useLoginModal from '../hooks/useLoginModal';
import LoginForm from '../LoginForm';

export default function LoginModal() {
  const loginModal = useLoginModal();

  return (
    <Modal isOpen={loginModal.isOpen} handleClose={loginModal.close}>
      <Modal.Header onClose={loginModal.close} title="" />
      <Modal.Content>
        <LoginForm />
      </Modal.Content>
    </Modal>
  );
}
