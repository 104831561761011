import { PiXCircle } from 'react-icons/pi';

import HStack from 'components/primitives/HStack';
import Spinner from 'components/primitives/Spinner';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';

export default function ErrorState() {
  return (
    <VStack vcenter hcenter fullHeight fullWidth>
      <PiXCircle size="32px" />
      <Text mt={2} ml={1} size="base" color="secondary" weight="medium">
        Invalid code or expired
      </Text>
      <Text mt={2} ml={1} size="xs" color="tertiary" weight="medium">
        Please try again
      </Text>
    </VStack>
  );
}
