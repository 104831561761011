import React from 'react';
import { styled } from '@mui/material';

import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';

interface Props {
  text: React.ReactNode;
}

export default function RequiredLabel({ text }: Props) {
  return (
    <HStack>
      <Text size="xs" color="secondary" weight="medium">
        {text}{' '}
        <Text
          size="xs"
          as="span"
          ml={'0px'}
          weight="medium"
          sx={{
            color: 'red.400',
          }}
        >
          *
        </Text>
      </Text>
    </HStack>
  );
}

export const RequiredAsterisk = () => {
  return <StyledSpan>*</StyledSpan>;
};

const StyledSpan = styled('span')({
  color: '#FF0000',
  marginRight: '1px',
});
