export type LocalStorageData = {
  [key: string]: boolean | string;
} | null;

const getStorageData = (key: string): LocalStorageData => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(key))
      return JSON.parse(localStorage.getItem(key) as string);
  }
  return null;
};

const setStorageData = (key: string, value: unknown): void =>
  localStorage.setItem(key, JSON.stringify(value));

const removeStorageData = (key: string): void => localStorage.removeItem(key);

export { getStorageData, removeStorageData, setStorageData };
