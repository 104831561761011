const sideBarDesktopWidth = 60;
const sideBarDesktopFullWidth = 220;
const sideBarMobileWidth = 311;
const topBarMobileHeight = 64;
const topBarDesktopHeight = 64;

const subnavigationSidebarWidth = 280;

export {
  sideBarDesktopFullWidth,
  sideBarDesktopWidth,
  sideBarMobileWidth,
  subnavigationSidebarWidth,
  topBarDesktopHeight,
  topBarMobileHeight,
};
