import { PaletteMode } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const lightTheme: DeepPartial<Theme> = {
  palette: {
    neutral: {
      900: '#282829',
      800: '#3C3C3D',
      700: '#7D7D80',
      600: '#979899',
      500: '#C8C9CC',
      400: '#E0E1E5',
      300: '#EBECF0',
      200: '#F3F4F5',
      100: '#F8F9FA',
      75: '#FFFFF',
      50: '#FFFFF',
      25: '#FFFFF',
    },
    base: {
      black: '#000000',
      darkNeutral: '#0A172A',
      white: '#FFFFFF',
    },
    blue: {
      600: '#1F3D99',
      500: '#2952CC',
      400: '#356BF6',
      300: '#9DB5FF',
      200: '#D6E0FF',
      100: '#EBF0FF',
      50: '#F3F6FF',
    },
    green: {
      600: '#059669',
      500: '#10B981',
      400: '#34D399',
      300: '#6EE7B7',
      200: '#A7F3D0',
      100: '#D1FAE5',
      50: '#ECFDF5',
    },
    yellow: {
      600: '#66460D',
      500: '#996A13',
      400: '#FFB020',
      300: '#FFD079',
      200: '#FFDFA6',
      100: '#FFEFD2',
      50: '#FFFAF1',
    },
    red: {
      600: '#E11D48',
      500: '#F43F5E',
      400: '#FB7185',
      300: '#FDA4AF',
      200: '#FECDD3',
      100: '#FFE4E6',
      50: '#FFF1F2',
    },
    violet: {
      600: '#524988',
      500: '#6E62B6',
      400: '#897AE3',
      300: '#B8AFEE',
      200: '#D0CAF4',
      100: '#E7E4F9',
      50: '#F8F7FD',
    },
    teal: {
      600: '#0F5156',
      500: '#10899E',
      400: '#25CBD6',
      300: '#7CE0E6',
      200: '#A8EAEF',
      100: '#D3F5F7',
      50: '#F2FCFD',
    },
    pink: {
      600: '#AB36F6',
      500: '#B65AF0',
      400: '#D38DFF',
      300: '#E1BAFA',
      200: '#EED2FF',
      100: '#F8EDFF',
      50: '#FCF8FF',
    },
    orange: {
      600: '#85462B',
      500: '#B25E3A',
      400: '#DE7548',
      300: '#EBAC91',
      200: '#F2C8B6',
      100: '#F8E3DA',
      50: '#FDF4F4',
    },
    grey: {
      600: '#8F95B2',
      500: '#C1C4D6',
      400: '#D8DAE5',
      300: '#E6E8F0',
      200: '#EDEFF5',
      100: '#F4F6FA',
      50: '#FAFBFF',
    },
    primary: {
      main: '#356BF6',
      mainTransparent: '#356BF609',
    },
    secondary: {
      main: '#AB36F6',
    },
    background: {
      default: '#F8F9FA',
      paper: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#3C3C3D',
      tertiary: '#979899',
      active: '#07182A',
      additional: '#69D9EC',
      white: '#FFFFFF',
      grey: '#525252',
    },
    success: {
      main: '#35B721',
    },
    warning: {
      main: '#c88824',
    },
    info: {
      main: '#445FF8',
    },
    error: {
      main: '#FF3535',
    },
    navigation: {
      color: {
        inactive: '#7D7D80',
        active: 'black',
      },
      background: {
        inactive: 'transparent',
        active: '#EBECF0',
        hover: {
          inactive: '#F3F4F5',
          active: '#EBECF0',
        },
      },
    },
    named: {
      green: '#8FDB81',
      orange: '#F3DEBD',
      blue: '#445FF8',
      red: '#E36762',
      grey: '#E9E7E7',
      placeholder: '#BEBEBE',
    },
  },
};

const darkTheme: DeepPartial<Theme> = {
  palette: {
    neutral: {
      900: '#FFFFFF',
      800: '#EDEFF5',
      700: '#D8DAE5',
      600: '#C1C4D6',
      500: '#8F95B2',
      400: '#696F8C',
      300: '#474D66',
      200: '#101840',
      100: '#0A172A',
      75: '#07182A',
      50: '#474D66',
      25: '#000000',
    },
    base: {
      black: '#FFFFFF',
      darkNeutral: '#28488c',
      white: '#0A172A',
    },
    blue: {
      600: '#9DB5FF',
      500: '#D6E0FF',
      400: '#0066FF',
      300: '#F3F6FF',
      200: '#F3F6FF',
      100: '#F3F6FF',
      50: '#F3F6FF',
    },
    green: {
      600: '#A3E6CD',
      500: '#DCF2EA',
      400: '#affcdd',
      300: '#F5FBF8',
      200: '#F5FBF8',
      100: '#F5FBF8',
      50: '#F5FBF8',
    },
    yellow: {
      600: '#FFD079',
      500: '#FFDFA6',
      400: '#FFEFD2',
      300: '#FFFAF1',
      200: '#FFFAF1',
      100: '#FFFAF1',
      50: '#FFFAF1',
    },
    red: {
      600: '#FDA4AF',
      500: '#FECDD3',
      400: '#FFE4E6',
      300: '#FFF1F2',
      200: '#FFF1F2',
      100: '#FFF1F2',
      50: '#FFF1F2',
    },
    violet: {
      600: '#B8AFEE',
      500: '#D0CAF4',
      400: '#E7E4F9',
      300: '#F8F7FD',
      200: '#F8F7FD',
      100: '#F8F7FD',
      50: '#F8F7FD',
    },
    teal: {
      600: '#7CE0E6',
      500: '#A8EAEF',
      400: '#D3F5F7',
      300: '#F2FCFD',
      200: '#F2FCFD',
      100: '#F2FCFD',
      50: '#F2FCFD',
    },
    pink: {
      600: '#E1BAFA',
      500: '#EED2FF',
      400: '#F8EDFF',
      300: '#FCF8FF',
      200: '#FCF8FF',
      100: '#FCF8FF',
      50: '#FCF8FF',
    },
    orange: {
      600: '#EBAC91',
      500: '#F2C8B6',
      400: '#F8E3DA',
      300: '#FDF4F4',
      200: '#FDF4F4',
      100: '#FDF4F4',
      50: '#FDF4F4',
    },
    grey: {
      600: '#525252',
      500: '#3C4F64',
      400: '#07182A',
      300: '#000000',
      200: '#000000',
      100: '#000000',
      50: '#000000',
    },
    primary: {
      main: '#356BF6',
      mainTransparent: '#356BF609',
    },
    secondary: {
      main: '#AB36F6',
    },
    background: {
      default: '#0A172A',
      paper: '#152f56',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#D8DAE5',
      tertiary: '#8F95B2',
      active: '#EDEFF5',
      additional: '#69D9EC',
      white: '#0A172A',
      grey: '#EDEFF5',
    },
    success: {
      main: '#35B721',
    },
    warning: {
      main: '#c88824',
    },
    info: {
      main: '#445FF8',
    },
    error: {
      main: '#FF3535',
    },
    navigation: {
      color: {
        inactive: '#818EA3',
        active: '#fff',
      },
      background: {
        inactive: 'transparent',
        active: '#0A172A',
        hover: {
          inactive: '#101840',
          active: '#0A172A',
        },
      },
    },
    named: {
      green: '#8FDB81',
      orange: '#F3DEBD',
      blue: '#445FF8',
      red: '#E36762',
      grey: '#E9E7E7',
      placeholder: '#BEBEBE',
    },
  },
};

export const commonTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 870,
      md: 975,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  shadows: {
    elevation0:
      '0px 1px 3px 0px rgba(16, 24, 64, 0.04), 0px 0px 0px 1px rgba(16, 24, 64, 0.02)',
    elevation1: '0px 4px 8px 0px rgba(16, 24, 64, 0.08)',
    elevation2: '0px 4px 8px 0px rgba(16, 24, 64, 0.16)',
    elevation3: '0px 12px 40px 0px rgba(16, 24, 64, 0.24)',
  },
};

export const getThemeBasedOnMode = (mode: PaletteMode) => ({
  ...(mode === 'light'
    ? {
        ...lightTheme,
        ...commonTheme,
      }
    : {
        ...darkTheme,
        ...commonTheme,
      }),
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            padding: '6px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          height: '40px',
          gap: '10px',
          padding: '0 14px',
          '& p': {
            fontSize: '16px',
            fontWeight: '500',
          },
          boxShadow: '0px 2px 2px rgba(0, 2, 2, 0.08)',
        },
        sizeMedium: {
          height: '50px',
        },
        sizeSmall: {
          height: '44px',
          fontSize: '14px',
        },
      },
    },
  },
});
