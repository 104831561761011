const shadesOfGreen = ['#7BE4F5', '#3BBDD4', '#1F899B'];
const shadesOfBlue = ['#7F9DFF', '#436AEE', '#2F4BA8'];
const shadesOfPurple = ['#C58CF5', '#9E3FED', '#712DA8'];
const shadesOfOrange = ['#FFBAB5', '#FD847C', '#BD635C'];

const activityColors = [
  shadesOfOrange,
  shadesOfGreen,
  shadesOfBlue,
  shadesOfPurple,
];

export { activityColors, shadesOfBlue };
