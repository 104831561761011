import { useLayoutEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { mobileBreakpoint } from '../../constants';

const useIsMobile = (breakpoint = mobileBreakpoint): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  const updateSize = (): void => {
    setIsMobile(window.innerWidth < breakpoint);
  };

  useLayoutEffect(() => {
    updateSize();

    const throttledOnUpdateSize = throttle(updateSize, 200);
    window.addEventListener('resize', throttledOnUpdateSize);

    return (): void => {
      throttledOnUpdateSize.cancel();
      window.removeEventListener('resize', throttledOnUpdateSize);
    };
  }, []);

  return isMobile;
};

export { useIsMobile };
