import { SxProps } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { Theme } from '@mui/system';

import Text from 'components/primitives/Text';

interface Props extends BaseTextFieldProps {
  label?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function Label({ label, sx }: Props) {
  if (!label) return null;
  return (
    <Text size="xs" color="secondary" weight="medium" sx={sx}>
      {label}
    </Text>
  );
}
