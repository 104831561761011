import React from 'react';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';

interface Props extends CheckboxProps {
  isError?: boolean;
}

export default function StyledCheckbox({ isError, ...props }: Props) {
  return (
    <Checkbox
      checkedIcon={<CheckedIcon />}
      icon={<Icon isError={isError} />}
      {...props}
    />
  );
}

const Icon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError?: boolean }>(({ theme, isError }) => ({
  width: 18,
  margin: 2,
  height: 18,
  borderRadius: 2,
  background: '#fff',
  boxShadow: isError
    ? `inset 0 0 0 1px ${theme.palette.named.red}`
    : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  'input:disabled ~ &': {
    boxShadow: 'none',
    // background: theme.palette.grey['300'],
    background: 'red',
  },
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    borderRadius: 2,
    backgroundColor: theme.palette.text.primary,
    display: 'block',
    width: 12,
    height: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%' + ')',
    content: '""',
  },
}));
