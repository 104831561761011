import { Project } from '@prisma/client';

import fetcher from 'utils/fetcher';
import { TeamResItem } from 'services/teamManagement/useGetTeams';

export const deleteTeam = async (teamId: string) => {
  return await fetcher(`/api/teamManagement/${teamId}`, {
    method: 'DELETE',
  });
};

export const editTeamName = async (teamId: string, teamName: string) =>
  await fetcher(`/api/teamManagement/${teamId}`, {
    method: 'PATCH',
    body: JSON.stringify({ teamName }),
  });

export const acceptInvitation = async (teamId: string) =>
  await fetcher(`/api/teamManagement/${teamId}`, {
    method: 'POST',
  });

export const acceptInvitationByUniqueId = async (
  invitationId: string,
): Promise<{ message: string; team: TeamResItem; project: Project }> =>
  await fetcher(`api/teamManagement/invitations/${invitationId}`, {
    method: 'POST',
  });
