import React from 'react';
import Confetti from 'react-confetti';
import { Box, IconButton, Stack, SxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/system';

import Text from 'components/primitives/Text';
import CloseIcon from 'theme/icons/x';

interface Props {
  onClose?: () => void;
  title: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  hideCloseButton?: boolean;
  headerOverlay?: React.ReactNode;
}

export default function Header({
  title,
  subtitle,
  onClose,
  children,
  sx,
  hideCloseButton,
  headerOverlay,
}: Props) {
  const theme = useTheme();

  return (
    <Stack
      gap={1}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'start',
        position: 'relative',
        padding: '24px 24px',
        background: theme.palette.background.paper,
        overflow: headerOverlay ? 'hidden' : 'auto',
        ...sx,
      }}
      direction="row"
    >
      {headerOverlay && (
        <Box position="absolute" left={0} top={0} width="100%" height="100%">
          {headerOverlay}
        </Box>
      )}
      <Box>
        <Text sx={{ fontWeight: '500', fontSize: '18px' }}>{title}</Text>
        {subtitle && (
          <Text mt={1} color="secondary" size="sm">
            {subtitle}
          </Text>
        )}
      </Box>
      {children}
      {!hideCloseButton && (
        <IconButton sx={{ p: '0' }} aria-label="close modal" onClick={onClose}>
          <CloseIcon stroke={theme.palette.text.secondary} />
        </IconButton>
      )}
    </Stack>
  );
}
