import { Role } from '@prisma/client';

import { isUserRole } from 'utils/userRoles';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import { useAppStateContext } from '../../../store/AppProvider';

export const useUserRights = () => {
  const { selectedTeam } = useGetTeams();
  const {
    user: { data },
  } = useAppStateContext();

  return {
    //user has partner rights when his selected team has active subscription
    hasPartnerRights:
      selectedTeam?.isSubscriptionActive || isUserRole(data?.roles, Role.ADMIN),
  };
};
