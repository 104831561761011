import { Controller, FieldErrors } from 'react-hook-form';
import { FormControlLabel, RadioGroup, SxProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/system';

import { Label } from 'components/Form';
import { useIsMobile } from 'utils/hooks';

import Radio from './Radio';

interface Props<T> {
  fieldName: string;
  label?: React.ReactNode;
  control: any;
  isRequired?: boolean;
  options: { value: T; label: React.ReactNode; tooltip?: string }[];
  color?: string;
  errors?: FieldErrors<any>;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  radioGroupSx?: SxProps<Theme>;
}

export default function RadioButtons<T>({
  fieldName,
  label,
  isRequired,
  options,
  control,
  color = '#000',
  errors,
  sx,
  labelSx,
  radioGroupSx,
}: Props<T>) {
  const isMobile = useIsMobile();

  const isError = !!errors?.[fieldName];

  return (
    <FormControl
      sx={{
        color,
        '& .MuiFormLabel-root': {
          color,
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '12px',
        },

        ...sx,
      }}
    >
      {label && <Label label={label} sx={labelSx} />}
      <Controller
        rules={{ required: isRequired && 'This field is required' }}
        control={control}
        name={fieldName}
        render={({ field: { ref, ...fieldProps } }) => (
          <RadioGroup {...fieldProps} row={!isMobile} sx={radioGroupSx}>
            {options?.map((option, index) => (
              <FormControlLabel
                inputRef={index == 0 ? ref : undefined}
                sx={{ mr: 4 }}
                key={index}
                value={option.value}
                control={<Radio {...{ isError, color }} />}
                label={option.label}
                title={option.tooltip}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
