import { useEffect } from 'react';
import { useSWRConfig } from 'swr';

import { INVITE_ID_STORAGE_KEY } from 'pages/join-team';
import { getStorageData, removeStorageData } from 'utils/storage';
import { acceptInvitationByUniqueId } from 'services/teamManagement';

import { useTeamStore } from '../team/teamStore';

export const useCheckInvitationInStorage = ({
  isUserLoggedIn,
}: {
  isUserLoggedIn: boolean;
}) => {
  const { mutate } = useSWRConfig();
  const { setTeamAndProject } = useTeamStore();

  useEffect(() => {
    const handleInvitationAccept = async () => {
      if (!isUserLoggedIn) return;

      const invitationTokenFromStorage = getStorageData(INVITE_ID_STORAGE_KEY);

      if (
        !invitationTokenFromStorage ||
        typeof invitationTokenFromStorage !== 'string'
      ) {
        return;
      }

      try {
        const response = await acceptInvitationByUniqueId(
          invitationTokenFromStorage,
        );

        if (response.team && response.project) {
          // mutate from useGetTeams() hook doesn't work
          await mutate('/api/teamManagement');
          // to avoid race conditions with setting project and team on initial load
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setTeamAndProject(response.team, response.project);
        }
        removeStorageData(INVITE_ID_STORAGE_KEY);
      } catch (e) {
        console.error(e);
      }
    };

    handleInvitationAccept();
  }, [isUserLoggedIn]);
};
