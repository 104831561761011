import React from 'react';
import NextProgress from 'next-progress';
import { useTheme } from '@mui/material';

export default function PageShiftProgressBar() {
  const theme = useTheme();

  return (
    <NextProgress
      color={theme.palette.primary.main}
      options={{ showSpinner: false }}
    />
  );
}
