import { FontSize, TextSizes } from '../types/FontSize';

const sizes: TextSizes = {
  inherit: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  xxs: {
    fontSize: 10,
    lineHeight: 12,
  },
  xs: {
    fontSize: 12,
    lineHeight: 16,
  },
  sm: {
    fontSize: 14,
    lineHeight: 20,
  },
  md: {
    fontSize: 15,
    lineHeight: 18,
  },
  base: {
    fontSize: 16,
    lineHeight: 24,
  },
  lg: {
    fontSize: 18,
    lineHeight: 28,
  },
  xl: {
    fontSize: 20,
    lineHeight: 28,
  },
  '2xl': {
    fontSize: 24,
    lineHeight: 32,
  },
  '3xl': {
    fontSize: 30,
    lineHeight: 36,
  },
  '4xl': {
    fontSize: 36,
    lineHeight: 40,
  },
  '5xl': {
    fontSize: 48,
    lineHeight: 1,
  },
  '6xl': {
    fontSize: 60,
    lineHeight: 1,
  },
  '7xl': {
    fontSize: 72,
    lineHeight: 1,
  },
  '8xl': {
    fontSize: 96,
    lineHeight: 1,
  },
  '9xl': {
    fontSize: 128,
    lineHeight: 1,
  },
};

export default sizes;
