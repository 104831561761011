import { LinearProgress } from '@mui/material';

import HStack from 'components/primitives/HStack';
import Spinner from 'components/primitives/Spinner';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';

export default function LoadingState() {
  return (
    <VStack vcenter hcenter fullHeight fullWidth>
      <LinearProgress
        variant="indeterminate"
        sx={{ width: '60px', borderRadius: 4 }}
      />
      <Text mt={2} ml={1} size="xs" color="tertiary" weight="medium">
        Sending a verification email...
      </Text>
    </VStack>
  );
}
