import { State } from './AppProvider';

const initialState: State = {
  user: {
    data: null,
    isLoading: true,
    isError: false,
  },
};

export { initialState };
