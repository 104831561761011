interface Props {
  color?: string;
  strokeWidth?: number;
}

export default function CheckIcon({
  color = '#ffffff',
  strokeWidth = 2,
}: Props) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L3.5 6.5L1 4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
