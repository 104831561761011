import { ForwardedRef, forwardRef } from 'react';
import { Stack } from '@mui/material';

import { StackProps } from '../common/types';

const VStack = forwardRef(
  (props: StackProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Stack
        {...props}
        ref={ref}
        width={props.fullWidth ? '100%' : props.width}
        height={props.fullHeight ? '100%' : props.height}
        direction="column"
        alignItems={props.hcenter ? 'center' : props.alignItems}
        justifyContent={props.vcenter ? 'center' : props.justifyContent}
      />
    );
  },
);

VStack.displayName = 'HStack';

export default VStack;
