import { createContext, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useSWR, { SWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import CountdownWrapper from 'components/CountdownWrapper';
import SplashScreen from 'components/SplashScreen';
import LoginModal from 'components/views/login/LoginModal';
import MissingSubscriptionModal from 'components/views/missing-subscription/MissingSubscriptionModal';
import OnboardingModal from 'components/views/register/OnboardingModal';
import RegisterModal from 'components/views/register/RegisterModal';
import RegistrationSuccessModal from 'components/views/register/RegistrationSuccessModal';
import fetcher from 'utils/fetcher';
import usePrefersReducedMotion from 'utils/hooks/usePrefersReducedMotion';
import { useGetTeamSlugs } from 'utils/teamManagement/useGetTeamSlugs';
import { useSmartlookAnalytics } from 'lib/smartlook';
import { User, UserInfo } from 'types/User';

import { useCheckInvitationInStorage } from './hooks/useCheckInvitationInStorage';
import { useCheckTermsRedirect } from './hooks/useCheckTermsRedirect';
import { initialState } from './initialState';

export interface State {
  user: {
    data?: User | null;
    isLoading: boolean;
    isError: boolean;
  };
}

const DynamicCalendlyModal = dynamic(
  () =>
    import('components/views/register/CalendlyModal').then(
      (mod) => mod.CalendlyModal,
    ),
  {
    ssr: false,
  },
);

export const AppStateContext = createContext<State>(initialState);

export function AppWrapper({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  const prefersReducedMotion = usePrefersReducedMotion();

  const [shouldStopShowingSplashScreen, setStopShowingSplashScreen] =
    useState(false);

  useEffect(() => {
    if (prefersReducedMotion) {
      setStopShowingSplashScreen(true);
    }

    setTimeout(() => {
      setStopShowingSplashScreen(true);
    }, 1200);
  }, [prefersReducedMotion]);

  const { teamSlug, projectSlug } = useGetTeamSlugs();

  const {
    data: userInfo,
    error: userFetchError,
    isLoading: isUserInfoLoading,
  } = useSWR<UserInfo>('/api/users', fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  useSWRImmutable(
    userInfo && teamSlug && projectSlug
      ? [`/api/${teamSlug}/${projectSlug}/session`, router.asPath]
      : null,
    () => fetcher(`/api/${teamSlug}/${projectSlug}/session`),
  );

  useSmartlookAnalytics({ user: userInfo });

  const shouldCancelSWR = !userInfo;

  useCheckTermsRedirect({ userFetchError, userInfo, isUserInfoLoading });
  useCheckInvitationInStorage({ isUserLoggedIn: Boolean(userInfo?.data) });

  const isSplashScreenVisible =
    !shouldStopShowingSplashScreen || isUserInfoLoading;

  // return <SplashScreen />;

  return (
    <>
      <AppStateContext.Provider
        value={{
          user: {
            data: userInfo?.data,
            isLoading: isUserInfoLoading,
            isError: userFetchError,
          },
        }}
      >
        <SWRConfig
          value={{
            isPaused: () => {
              return shouldCancelSWR;
            },
          }}
        >
          {isSplashScreenVisible ? <SplashScreen /> : children}
          <OnboardingModal />
          <RegisterModal />
          <RegistrationSuccessModal />
          <DynamicCalendlyModal />
          <LoginModal />
          <MissingSubscriptionModal />
          {/*<InvitationCheckWithModal />*/}
        </SWRConfig>
      </AppStateContext.Provider>
    </>
  );
}

export function useAppStateContext() {
  const state = useContext(AppStateContext);

  return state;
}

export function useIsLoggedIn() {
  const { user } = useAppStateContext();

  return Boolean(user.data?.id);
}
