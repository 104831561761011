type Size = 'mini' | 'small' | 'default' | 'large';

const buttonSizes: Record<
  Size,
  {
    fontSize: string;
    lineHeight: string;
    px: string;
    py: string;
    iconSize: string;
  }
> = {
  mini: {
    fontSize: '12px',
    lineHeight: '16px',
    px: '12px',
    py: '4px',
    iconSize: '12px',
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
    px: '12px',
    py: '8px',
    iconSize: '16px',
  },
  default: {
    fontSize: '16px',
    lineHeight: '24px',
    px: '16px',
    py: '12px',
    iconSize: '20px',
  },
  large: {
    fontSize: '18px',
    lineHeight: '24px',
    px: '12px',
    py: '16px',
    iconSize: '20px',
  },
};

export default buttonSizes;
