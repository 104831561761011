import { useEffect } from 'react';
import { useRouter } from 'next/router';

import isMaintenance from 'utils/isMaintenance';
import { UserInfo } from 'types/User';

export const useCheckTermsRedirect = ({
  userInfo,
  userFetchError,
  isUserInfoLoading,
}: {
  userInfo?: UserInfo;
  userFetchError: boolean;
  isUserInfoLoading: boolean;
}) => {
  const router = useRouter();

  useEffect(() => {
    if (!userInfo?.data) {
      return;
    }

    if (isMaintenance) {
      return;
    }

    if (router.pathname === '/terms') {
      return;
    }

    if (isUserInfoLoading || userFetchError) {
      return;
    }

    if (userInfo.data?.isTosAccepted) {
      return;
    }

    if (router.query.login) delete router.query.login;

    router.replace({
      pathname: '/terms',
      query: router.query,
    });
  }, [router, userInfo, userFetchError, isUserInfoLoading]);
};
